import { Avatar, Tag } from '@kaya/kaya-ui-design-system-pb';
import { SETTING_TYPES } from '../../../constants';
import { useAdminPanelContext } from '../../../contexts';
import { getInitials } from '../../../utils';
import { useAllKPLs } from '../../../hooks';

export const Profile = () => {
    const { findTitleByConfigKey } = useAllKPLs();
    const { selectedProfile } = useAdminPanelContext();

    const settings = SETTING_TYPES.map(setting => ({
        label: setting.name,
        key: setting.key,
        enabled: selectedProfile?.metadata?.default[setting.key],
    }));

    return (
        <>
            <div className="flex justify-between items-center mt-4">
                <div className="flex items-center gap-x-4">
                    <Avatar
                        imageURL={selectedProfile?.avatarUrl}
                        className="cursor-pointer"
                        shape="circle"
                        size="lg"
                        type={selectedProfile?.avatarUrl ? 'image' : 'text'}
                        icon="ri-user-3-line"
                        textLabel={getInitials(selectedProfile?.name)}
                    />
                    <div className="flex flex-col">
                        <p className="text-N-900 font-500 text-md">{selectedProfile?.name}</p>
                        <p className="text-N-600 text-sm">{selectedProfile?.email}</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-y-2">
                <div>
                    <div className="flex items-center gap-x-2 mt-[3vh] mb-2">
                        <p className="text-N-900 font-500 text-sm w-fit whitespace-nowrap">Enabled KPLs</p>
                        <div className="w-full h-[1px] mt-[2px] bg-[#E5E7EB]" />
                    </div>

                    <div className="flex flex-wrap items-center gap-2">
                        {Boolean(selectedProfile?.hasAllKPLs) ? (
                            <div className="flex flex-row">
                                <span>All KPLs are enabled with</span>
                                <span className="mx-1">
                                    <Tag
                                        label={findTitleByConfigKey(selectedProfile?.metadata?.default?.feature)}
                                        appearance="green"
                                    />
                                </span>
                                <span>as default</span>
                            </div>
                        ) : selectedProfile?.currentKPLs?.length > 0 ? (
                            selectedProfile?.currentKPLs?.map((kpl: any, index: number) => (
                                <Tag
                                    key={index}
                                    label={kpl.title}
                                    appearance={
                                        kpl?.configKey == selectedProfile?.metadata?.default?.feature ? 'green' : 'sky'
                                    }
                                    hasIcon={true}
                                    icon="ri-checkbox-circle-fill"
                                />
                            ))
                        ) : (
                            <p className="text-sm text-N-500">KPLs are not enabled for this user</p>
                        )}
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-x-2 mt-[3vh] mb-2">
                        <p className="text-N-900 font-500 text-sm w-fit whitespace-nowrap">Enabled Models</p>
                        <div className="w-full h-[1px] mt-[2px] bg-[#E5E7EB]" />
                    </div>

                    <div className="flex flex-wrap gap-2">
                        {selectedProfile?.models?.map((model: any, index: number) => (
                            <Tag
                                label={model?.label}
                                hasIcon={true}
                                appearance={model?.id === selectedProfile?.metadata?.default?.model ? 'green' : 'sky'}
                                size="medium"
                                icon="ri-checkbox-circle-fill"
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-x-2 mt-[3vh] mb-2">
                        <p className="text-N-900 font-500 text-sm w-fit whitespace-nowrap">Enabled Settings</p>
                        <div className="w-full h-[1px] mt-[2px] bg-[#E5E7EB]" />
                    </div>

                    <div className="flex items-center gap-x-2">
                        {settings.map((setting, index) => (
                            <Tag
                                key={index}
                                label={setting.label}
                                appearance={setting.enabled ? 'green' : 'neutral'}
                                hasIcon={true}
                                icon={setting.enabled ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
