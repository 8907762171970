import { Button, Drawer, Tooltip, UIModalV3 } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AdminPanelModalActions, useAdminPanelContext, useChatContext } from '../../../../../contexts';
import { IPrompt, useMobile, usePromptForm } from '../../../../../hooks';
import { CN, getFilteredModelsByUser } from '../../../../../utils';
import { PromptAddForm } from './common/prompt-add-form/PromptAddForm';
import { PromptView } from './common/prompt-view/PromptView';

interface IKPLViewContainerProps {
    isPromptViewModalOpen: boolean;
    setPromptViewModalOpen: Function;
    selectedPrompt?: IPrompt;
    fetchTableData?: Function | undefined;
}

export const PromptFormContainer = ({
    isPromptViewModalOpen,
    setPromptViewModalOpen,
    selectedPrompt,
    fetchTableData,
}: IKPLViewContainerProps) => {
    const { selectedPromptAction, setSelectedPromptAction, setPromptAction } = useAdminPanelContext();
    const { currentUserConfig } = useChatContext();
    const modelTypes = getFilteredModelsByUser(currentUserConfig);
    const formState = usePromptForm(selectedPrompt, modelTypes);
    const isMobile = useMobile();
    const isView = selectedPromptAction === AdminPanelModalActions?.VIEW ?? false;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isPromptViewModalOpen) {
            formState.refetch();
        }
    }, [isPromptViewModalOpen]);

    const save = async () => {
        try {
            setIsLoading(true);
            await formState.handleSaveAndUpdatePrompt();
            setPromptAction('save' + Date.now());
            if (fetchTableData) await fetchTableData();
        } catch (error) {
            toast.error('Failed to save prompt template');
        } finally {
            setIsLoading(false);
            setPromptViewModalOpen(false);
        }
    };

    const deleteTemplate = async () => {
        try {
            await formState.handleDeletePrompt();
            setPromptViewModalOpen(false);
            setPromptAction('delete' + Date.now());
        } catch (error) {
            toast.error('Failed to delete prompt template');
        }
    };

    return (
        <>
            <Drawer
                isOpen={isPromptViewModalOpen && selectedPromptAction !== AdminPanelModalActions?.DELETE}
                onClose={() => {
                    formState.clearState();
                    setPromptViewModalOpen(false);
                    setSelectedPromptAction(null);
                }}
                title={
                    isView
                        ? 'View Prompt'
                        : selectedPromptAction === AdminPanelModalActions?.CREATE
                          ? 'Add Prompt'
                          : 'Edit Prompt'
                }
                fullWidth={isMobile}
                width={800}
                isHideClose={true}
                closableOnClickOutside={false}
                footer={
                    <div className="flex items-center justify-between gap-x-2">
                        {formState?.validationErrorMsgs?.length > 0 && (
                            <Tooltip
                                id={'validation-error-tooltip-user-section'}
                                tooltipType="detailed"
                                subText={
                                    <ul>
                                        {formState?.validationErrorMsgs?.map((data: any, key: any) => (
                                            <li key={key} className="flex items-center justify-start gap-3">
                                                <span>
                                                    <i className="ri-information-fill text-[18px]" />
                                                </span>
                                                <span>{data}</span>
                                            </li>
                                        ))}
                                    </ul>
                                }
                                variant="dark"
                            >
                                <span className="w-full text-R-600 flex items-center text-xs sm:gap-x-0 gap-x-2">
                                    <span> Ensure all fields are filled out correctly</span>{' '}
                                    <i className="sm:hidden block ri-question-line" />
                                </span>
                            </Tooltip>
                        )}
                        <div className="flex items-center justify-end gap-x-2 flex-grow">
                            <Button
                                appearance="primary"
                                size="medium"
                                view="outline"
                                onClick={() => {
                                    formState.clearState();
                                    setPromptViewModalOpen(false);
                                    setSelectedPromptAction(null);
                                }}
                            >
                                {isView ? 'Close' : 'Cancel'}
                            </Button>
                            <Button
                                appearance="primary"
                                size="medium"
                                view="solid"
                                iconBefore={isView ? 'ri-pencil-line' : 'ri-article-line'}
                                isLoading={isView ? false : isLoading}
                                onClick={() => {
                                    isView ? setSelectedPromptAction(AdminPanelModalActions?.EDIT) : save();
                                }}
                                isDisabled={!isView && !formState?.validated}
                            >
                                {isView ? AdminPanelModalActions?.EDIT : AdminPanelModalActions?.SAVE}
                            </Button>
                        </div>
                    </div>
                }
            >
                <div
                    className={CN('w-full h-full pb-[76px] overflow-auto', {
                        '!h-[calc(100dvh-150px)] !pb-0': isMobile,
                    })}
                >
                    {isView ? (
                        <PromptView selectedPrompt={selectedPrompt ?? null} />
                    ) : (
                        <PromptAddForm formState={formState} />
                    )}
                </div>
            </Drawer>

            <UIModalV3
                boxedBody={false}
                header={
                    <div className="flex items-center gap-x-2">
                        <i className="ri-delete-bin-line text-md" />
                        <p className="text-N-600 font-500 text-md">Are you sure?</p>
                    </div>
                }
                footer={
                    <div className="flex items-center gap-x-2 justify-end">
                        <Button
                            appearance="danger"
                            size="medium"
                            view="outline"
                            iconBefore="ri-delete-bin-line"
                            onClick={deleteTemplate}
                        >
                            {AdminPanelModalActions?.DELETE}
                        </Button>
                    </div>
                }
                isOpen={isPromptViewModalOpen && selectedPromptAction === AdminPanelModalActions?.DELETE}
                onClose={() => {
                    setPromptViewModalOpen(false);
                }}
                setOpen={() => {
                    setSelectedPromptAction(null);
                    setPromptViewModalOpen(false);
                }}
                width="700px"
            >
                <div className="py-[16px] px-[24px]">
                    <p>This will delete this prompt permanently. You can't undo this action.</p>
                </div>
            </UIModalV3>
        </>
    );
};
