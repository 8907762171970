import { Button, Drawer } from '@kaya/kaya-ui-design-system-pb';
import React, { useState } from 'react';
import { ActiveStatus, ChatType } from '../../../__generated__/graphql';
import { ALL_VALUE_CAPITALIZED } from '../../../constants';
import { useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { CN } from '../../../utils';
import { KPLFilterContainer } from './common/kpl-filter-container';
import { KPLTableContainer } from './common/kpl-table-container';

export type IFilterCriteria = {
    chatType: ChatType | typeof ALL_VALUE_CAPITALIZED;
    setChatType: React.Dispatch<React.SetStateAction<ChatType | typeof ALL_VALUE_CAPITALIZED>>;
    status: ActiveStatus | typeof ALL_VALUE_CAPITALIZED;
    setStatus: React.Dispatch<React.SetStateAction<ActiveStatus | typeof ALL_VALUE_CAPITALIZED>>;
};

export type IAdminFilterContainerProps = {
    filterCriteria: IFilterCriteria;
};

export const KPLManagementContainer = () => {
    const { isCollapsed, isMenuCollapsed } = useSideBarContext();
    const [chatType, setChatType] = useState<ChatType | typeof ALL_VALUE_CAPITALIZED>(ALL_VALUE_CAPITALIZED);
    const [status, setStatus] = useState<ActiveStatus | typeof ALL_VALUE_CAPITALIZED>(ALL_VALUE_CAPITALIZED);
    const isMobile = useMobile();
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);

    const filterCriterias: IFilterCriteria = {
        chatType,
        setChatType,
        status,
        setStatus,
    };

    return (
        <div
            className={CN('admin-panel flex xxl:flex-col gap-x-16 w-full', {
                'gap-x-6': isMenuCollapsed || !isCollapsed,
            })}
        >
            <div className="sm:justify-end hidden xxl:flex">
                <Button
                    onClick={() => {
                        setIsFilterDrawerOpen(true);
                    }}
                    className="mb-3 text-B-500"
                    iconBefore="ri-equalizer-line"
                >
                    <p className="text-sm font-400 ">Filters</p>
                </Button>
            </div>
            <div className="xxl:hidden">
                <KPLFilterContainer filterCriteria={filterCriterias} />
            </div>

            <KPLTableContainer filterCriteria={filterCriterias} />

            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => {
                    setIsFilterDrawerOpen(false);
                }}
                title="Filters"
                fullWidth={isMobile}
                isHideClose={true}
                headerIcon="ri-contacts-line"
                width={800}
                closableOnClickOutside={true}
                className="z-[999]"
                footer={
                    <div className="flex items-center gap-x-[8px] justify-end">
                        <Button
                            view="outline"
                            appearance="primary"
                            size="medium"
                            onClick={() => {
                                setIsFilterDrawerOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                }
                children={
                    <>
                        <div className="p-5 mb-[10vh]">
                            <KPLFilterContainer filterCriteria={filterCriterias} />
                        </div>
                    </>
                }
            />
        </div>
    );
};
