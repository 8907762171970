export const FOLLOW_UP_EMAIL = `
## Next Steps and How We can Help You Achieve Your Financial Goals

Dear Aurora,

I hope you’re doing well! It was wonderful speaking with you, and I’ve carefully reviewed your current portfolio and long-term financial goals. Based on our discussion, I’ve crafted an investment strategy tailored to ensure you meet your retirement goals, support your children’s education, and potentially invest in real estate.

Below, I’ve outlined our recommended approach, focusing on diversification, risk management, and tax efficiency:

### 1. Retirement Planning (15-Year Horizon)
Given your retirement timeline, we’ll focus on a balanced portfolio of growth and stability:

- **Equities ($1,250,379.4 – 30% allocation)**: We’ll maintain your current allocation but adjust for sector-specific investments in technology, healthcare, and renewable energy. These sectors are expected to have solid growth over the next decade.
  - **Technology (15%)**: As you’re already familiar with the tech industry, investing in companies leading the AI, cloud computing, and software sectors will capitalize on growth.
  - **Healthcare (10%)**: The healthcare industry is continually expanding, and long-term growth in biopharma and healthcare tech makes it a valuable sector.
  - **Renewable Energy (5%)**: Green energy is projected to grow substantially, making this a promising sector for future returns.

- **Fixed Income ($1,045,098.93 – 25% allocation)**: Continue your strong fixed income allocation, but I recommend exploring municipal bonds and treasury inflation-protected securities (TIPS). These investments are tax-efficient and offer steady returns, which can stabilize your portfolio.
  - **Municipal Bonds (10%)**: Offering tax-free returns, particularly beneficial for high-income individuals.
  - **TIPS (15%)**: Protects against inflation, ensuring that your portfolio retains its value as living costs rise.

- **Mutual Funds ($2,585,749.50 – 20% allocation)**: I suggest maintaining your mutual funds but shifting focus toward funds that blend growth and income. Allocating 10% into **global equity mutual funds** will help diversify internationally, while **income-focused mutual funds** (10%) can provide reliable dividend yields.

### 2. Education Fund for Your Children
- **Private Equity Fund ($225,000 – 10% allocation)**: This is already a substantial investment. I recommend considering a portion of this for educational savings, such as a **529 plan** or a dedicated **growth-focused ETF**. These funds can grow tax-free for education expenses, giving you peace of mind as the education expenses grow.
  
### 3. Real Estate Investment Opportunities
- **Real Estate ($500,000 – 15% allocation)**: With the potential for long-term growth, investing in **Real Estate Investment Trusts (REITs)** will allow you to benefit from real estate market growth without direct management responsibility. 
  - **REITs (10%)**: REITs focused on commercial and residential properties can provide regular dividends, which could be reinvested or used to fund your retirement.
  - **Direct Real Estate Investment (5%)**: You may want to keep some capital available for future direct real estate investments, particularly in markets like Austin, Denver, or Raleigh, which are seeing rapid growth in housing demand.

### 4. Tax Efficiency and Risk Management
As we move forward with transitioning your portfolio:
- We will rebalance your portfolio quarterly to maintain an optimal risk-reward ratio and ensure that any gains are realized in a tax-efficient manner.
- We’ll explore tax-loss harvesting to offset any capital gains with losses, further reducing your tax liability.

### 5. Cash Reserve and Liquidity
- **Cash and Money Market Funds ($110,635.78 – 5% allocation)**: It’s important to maintain liquidity for emergencies or immediate opportunities. We’ll keep this portion intact but recommend placing a small portion into higher-yield savings accounts or short-term bonds for better returns.

This diversified approach is designed to balance growth with security, taking into account your 15-year horizon for retirement, your children’s education needs, and your interest in real estate investment. I’ll continue to review tax-efficient strategies, ensuring your portfolio grows without unnecessary liabilities.

I’ve attached a more detailed breakdown of the proposed allocations and tax strategies for your review. Please feel free to reach out with any questions or if you'd like to discuss further adjustments.

Looking forward to working together to achieve your financial goals!

Best regards,  
Orion Starling  
Wealth Manager  
Pinnacle Wealth Advisors
`;
