import { Button, Drawer } from '@kaya/kaya-ui-design-system-pb';
import React, { useState } from 'react';
import { useSideBarContext } from '../../../contexts';
import { useMobile } from '../../../hooks';
import { CN } from '../../../utils';
import { UserFilterContainer } from '../UserManagement/UserFilterContainer';
import { UserTableContainer } from '../UserManagement/UserTableContainer';
import { ALL_VALUE } from '../../../constants';

export type IFilterUserCriteria = {
    model: string | null;
    setModel: React.Dispatch<React.SetStateAction<string | null>>;
    feature: string[];
    setFeature: React.Dispatch<React.SetStateAction<string[]>>;
    userType: string | null;
    setUserType: React.Dispatch<React.SetStateAction<string | null>>;
};

export type IAdminFilterUserContainerProps = {
    filterUserCriteria: IFilterUserCriteria;
};

export const UserManagementContainer = () => {
    const [model, setModel] = useState<string | null>(ALL_VALUE);
    const [feature, setFeature] = useState<string[]>([]);
    const [userType, setUserType] = useState<string | null>(ALL_VALUE);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);

    const isMobile = useMobile();
    const { isCollapsed, isMenuCollapsed } = useSideBarContext();

    const filterUserCriterias: IFilterUserCriteria = {
        model,
        setModel,
        feature,
        setFeature,
        userType,
        setUserType,
    };

    return (
        <div
            className={CN('admin-panel flex xxl:flex-col gap-x-16 w-full overflow-auto', {
                'gap-x-6': isMenuCollapsed || !isCollapsed,
            })}
        >
            <div className="sm:justify-end hidden xxl:flex">
                <Button
                    onClick={() => {
                        setIsFilterDrawerOpen(true);
                    }}
                    className="mb-3 text-B-500"
                    iconBefore="ri-equalizer-line"
                >
                    <p className="text-sm font-400 ">Filters</p>
                </Button>
            </div>
            <div className="xxl:hidden">
                <UserFilterContainer filterUserCriteria={filterUserCriterias} />
            </div>

            <UserTableContainer filterUserCriteria={filterUserCriterias} />

            <Drawer
                isOpen={isFilterDrawerOpen}
                onClose={() => {
                    setIsFilterDrawerOpen(false);
                }}
                title="Filters"
                isHideClose={true}
                fullWidth={isMobile}
                headerIcon="ri-contacts-line"
                width={800}
                closableOnClickOutside={true}
                className="z-[999]"
                footer={
                    <div className="flex items-center gap-x-[8px] justify-end">
                        <Button
                            view="outline"
                            appearance="primary"
                            size="medium"
                            onClick={() => {
                                setIsFilterDrawerOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                }
                children={
                    <>
                        <div className="p-5 mb-[10vh]">
                            <UserFilterContainer filterUserCriteria={filterUserCriterias} />
                        </div>
                    </>
                }
            />
        </div>
    );
};
