import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { NewUserInput, UpdateUserInput, UserType } from '../../../__generated__/graphql';
import { useAdminPanelContext } from '../../../contexts';
import { ADD_NEW_USER, UPDATE_USER } from '../../../graphql';

export const useUserSubmission = (refetchData?: any, isEdit?: boolean) => {
    const { userFormData, resetUserFormData } = useAdminPanelContext();
    const [addNewUser] = useMutation(ADD_NEW_USER);
    const [updateUserDetails] = useMutation(UPDATE_USER);

    const errorMessages: Record<string, string> = {
        emailField:
            userFormData.metadata.userType === UserType.User
                ? 'Please enter a valid email address.'
                : 'Please enter a valid domain address.',
        passwordField: 'Password must be between 8 and 20 characters.',
        firstNameField: 'First name is required.',
        lastNameField: 'Last name is required.',
        featuresField: 'Please select at least one KPL.',
        modelsField: 'Please select at least one model.',
        defaultModelField: 'A default model selection is required.',
        defaultFeatureField: 'Please select a default KPL.',
    };

    // Validate email based on user type
    const isValidEmail = Boolean(
        userFormData.metadata.userType === UserType.User
            ? userFormData.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userFormData.email)
            : userFormData.email && /^(?!:\/\/)([A-Z0-9.-]+)\.[A-Z]{2,}$/i.test(userFormData.email)
    );

    // Validate password (8-20 characters)
    const isValidPassword = userFormData.password.length >= 8 && userFormData.password.length <= 20;

    // Validate required fields
    const validationErrors = {
        emailField: !isValidEmail,
        passwordField:
            userFormData.metadata.userType !== UserType.Domain &&
            (!isEdit || userFormData.isPasswordUpdate) &&
            !isValidPassword,
        firstNameField:
            userFormData.metadata.userType !== UserType.Domain &&
            (!userFormData.firstName || userFormData.firstName.trim() === ''),
        lastNameField:
            userFormData.metadata.userType !== UserType.Domain &&
            (!userFormData.lastName || userFormData.lastName.trim() === ''),
        featuresField: !userFormData.features || userFormData.features.length === 0,
        modelsField: !userFormData.models || userFormData.models.length === 0,
        defaultModelField: !userFormData.metadata.default.model || userFormData.metadata.default.model.trim() === '',
        defaultFeatureField:
            !userFormData.metadata.default.feature || userFormData.metadata.default.feature.trim() === '',
    };

    // Check if there are any validation errors
    const hasErrors = Object.values(validationErrors).some(Boolean);

    const handleSubmit = async (isNewUser: boolean) => {
        if (hasErrors) {
            toast.error('Please correct the validation errors.');
            return;
        }

        try {
            const userInputData: NewUserInput = {
                email: userFormData.email,
                firstName: userFormData.firstName,
                lastName: userFormData.lastName,
                password: userFormData.password,
                features: userFormData.features,
                models: userFormData.models,
                metadata: {
                    default: {
                        model: userFormData.metadata.default.model,
                        feature: userFormData.metadata.default.feature,
                        webSearch: userFormData.metadata.default.webSearch,
                        dynamicPrompts: userFormData.metadata.default.dynamicPrompts,
                        noLLM: userFormData.metadata.default.noLLM,
                    },
                    userType: userFormData.metadata.userType,
                },
                override: userFormData.override,
                isTempPassword: Boolean(userFormData.isTemporaryPass),
            };

            const userUpdateInput: UpdateUserInput = {
                ...userInputData,
                passwordUpdated: userFormData.isPasswordUpdate !== false,
            };

            // Submit form data based on user type
            if (isNewUser) {
                await addNewUser({ variables: { input: userInputData } });
                toast.success('User added successfully');
            } else {
                await updateUserDetails({ variables: { input: userUpdateInput } });
                toast.success('User updated successfully');
            }
        } catch (error) {
            console.error(error);
            toast.error(isNewUser ? 'Failed to add user' : 'Failed to update user');
        } finally {
            resetUserFormData();
            refetchData && refetchData();
        }
    };

    // Function to convert validation errors into the required format
    const getValidationErrors = (validationErrors: any): any => {
        return Object.entries(validationErrors)
            .filter(([key, isError]) => isError)
            .map(([key]) => ({
                id: key.toUpperCase(),
                name: errorMessages[key] || 'Unknown error',
            }));
    };

    const validationErrorMsgs = getValidationErrors(validationErrors);

    return {
        handleSubmit,
        hasErrors,
        validationErrors,
        validationErrorMsgs,
    };
};
