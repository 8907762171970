import { FilterCard, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { UserType } from '../../../__generated__/graphql';
import { ALL_VALUE } from '../../../constants';
import { KPLSelect, ModelSelect } from '../custom-select-components';
import { IAdminFilterUserContainerProps } from '../user-management-container';

export const UserFilterContainer = ({ filterUserCriteria }: IAdminFilterUserContainerProps) => {
    const { model, setModel, setFeature, feature, userType, setUserType } = filterUserCriteria;

    return (
        <div className="admin-panel__filter-section sm:w-full min-w-[296px] flex flex-col gap-y-6 pb-[30vh]">
            <div className="min-h-[36px] flex items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                {/* Models */}
                <FilterCard
                    title="Models"
                    showClear={model !== ALL_VALUE ? true : false}
                    show
                    handleClear={() => {
                        setModel(ALL_VALUE);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <ModelSelect
                            hasAllOption={true}
                            value={model}
                            onChange={(e: any) => {
                                setModel(e.name === ALL_VALUE ? e.name : e.model);
                            }}
                        />
                    </div>
                </FilterCard>

                <FilterCard
                    title="User Type"
                    showClear={userType !== ALL_VALUE ? true : false}
                    show
                    handleClear={() => {
                        setUserType(ALL_VALUE);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            placeholder="Search User Type"
                            value={userType || ''}
                            view="default"
                            items={[UserType.Domain, UserType.User, ALL_VALUE]}
                            onChange={(e: any) => {
                                setUserType(e);
                            }}
                        />
                    </div>
                </FilterCard>

                <FilterCard
                    title="KPL"
                    showClear={feature.length !== 0 ? true : false}
                    show
                    handleClear={() => {
                        setFeature([]);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <KPLSelect
                            hasAllOption={true}
                            value={feature[0] || ALL_VALUE}
                            onKPLChange={(e: any) => {
                                if (e.title === ALL_VALUE) {
                                    setFeature([]);
                                    return;
                                }

                                setFeature([e.configKey]);
                            }}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
