import { useForceUpdate } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { FeatureType } from '../../../__generated__/graphql';
import { useChatContext } from '../../../contexts';
import { GenerateTechnicalSpecContent, LiveCustomerAgent, SprintAnalysisReport, TimesheetContent } from '../../pages';
import { WealthManagementTranscriptAnalysis } from '../wealth-management-transcript-analysis';
import { PageLoading } from '../../common/full-page-loader';

const CustomKPLComponent = () => {
    const { featureType, conversationId } = useChatContext();
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (conversationId) {
            setShowLoading(true);

            setTimeout(() => {
                setShowLoading(false);
            }, 100);
        }
    }, [conversationId]);

    if (showLoading) {
        return <PageLoading />;
    }

    switch (featureType) {
        case FeatureType.GenerateTechnicalSpec:
            return <GenerateTechnicalSpecContent />;
        case FeatureType.SprintAnalysisReport:
            return <SprintAnalysisReport />;
        case FeatureType.ClientKbSantander:
            return <LiveCustomerAgent />;
        case FeatureType.Timesheet:
            return <TimesheetContent />;
        case FeatureType.WealthManagementTranscriptAnalysis:
            return <WealthManagementTranscriptAnalysis />;
        default:
            <></>;
    }
};

export { CustomKPLComponent };
