import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown, Link, Tag } from '@kaya/kaya-ui-design-system-pb';
import { useCallback, useEffect, useRef, useState } from 'react';
import { columnsArrayType } from '../components/common/table';
import { PAGE_SIZE } from '../constants';
import { AdminPanelModalActions, useAdminPanelContext, useChatContext } from '../contexts';
import { GET_PROMPT_TEMPLATES_BY_CRITERIA } from '../graphql';
import { deConstantCase, deUnderscore, getFilteredModelsByUser } from '../utils';

export type IPromptMetadata = {
    kpl: string;
    model: string;
};

export type IPrompt = {
    id: number;
    templateKey: string;
    templateContent: string;
    category: string;
    variables: string[];
    metadata: IPromptMetadata;
};

export type IPromptFilter = {
    model?: any;
    kpl?: any;
    category?: any;
    search?: string;
    setSearch?: Function;
    count?: number;
    setCount?: Function;
};

export const usePromptTable = ({ model, kpl, category, search, setCount }: IPromptFilter) => {
    const [isPromptViewModalOpen, setPromptViewModalOpen] = useState<boolean>(false);
    const [selectedPrompt, setSelectedPrompt] = useState<IPrompt>();
    const [tableData, setTableData] = useState<IPrompt[]>([]);
    const [dataRemaining, setDataRemaining] = useState(true);
    const [tableLoadingText, setTableLoadingText] = useState('Loading...');
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [queryPage, setQueryPage] = useState(0);
    const [previousScrollTop, setPreviousScrollTop] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    const scrollableRef = useRef<HTMLDivElement>(null);

    const tableElementId = 'prompt-listing-table-wrapper';
    const count = tableData.length || 0;

    const { setSelectedPromptAction, promptAction } = useAdminPanelContext();
    const { currentUserConfig } = useChatContext();
    const modelTypes = getFilteredModelsByUser(currentUserConfig);

    const [getPromptTemplates, { loading, fetchMore }] = useLazyQuery(GET_PROMPT_TEMPLATES_BY_CRITERIA);

    //get model name using model type
    const getPromptModelName = (selectedModel: string): string => {
        const modelType = modelTypes.find(({ model }) => model === selectedModel);
        return modelType ? modelType.name : 'N/A';
    };

    const loadMoreData = async () => {
        setTableLoadingText('Loading more prompt templates...');

        if (!tableData || loading || isLoadingMore) return;
        const nextPage = queryPage + 1;

        if (totalPages >= nextPage) {
            setIsLoadingMore(true);
            await fetchMore({
                variables: {
                    criteria: {
                        category: category,
                        key: search === '' ? null : search?.replace(/[\s\n\t]/g, '_'),
                        kpl: kpl,
                        model: model,
                        page: nextPage,
                        pageSize: PAGE_SIZE,
                    },
                },
            }).then(({ data: newData }) => {
                if (Number(newData?.getPromptTemplateByCriteria?.totalPages) === nextPage) {
                    setDataRemaining(false);
                }

                if (newData && newData.getPromptTemplateByCriteria) {
                    setTableData([
                        ...tableData,
                        ...((newData.getPromptTemplateByCriteria?.promptTemplates ?? []).filter(
                            item => item !== null
                        ) as IPrompt[]),
                    ]);
                    setQueryPage(nextPage);
                }

                setIsLoadingMore(false);

                setTableLoadingText('Loading...');
            });
        }
    };

    const handleScroll = useCallback(
        (e: Event) => {
            const target = e.target as HTMLDivElement;
            const { scrollTop, scrollHeight, clientHeight } = target;
            const isScrollingDown = scrollTop > previousScrollTop;
            setPreviousScrollTop(scrollTop);
            const scrollPercentage = scrollTop + clientHeight >= scrollHeight - 50;

            if (isScrollingDown && scrollPercentage && dataRemaining) {
                loadMoreData();
            }
        },
        [loadMoreData, dataRemaining]
    );

    // Scroll event listener
    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollableElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll, scrollableRef]);

    useEffect(() => {
        if (!isLoadingMore && tableData?.length) {
            heightLightLatestLoadedRow();
        }
    }, [isLoadingMore]);

    const heightLightLatestLoadedRow = () => {
        if (queryPage > 1) {
            const tableRows = document.querySelectorAll('table tbody tr');
            const newlyLoadedPageIndex = (queryPage - 1) * 25;

            for (let i = newlyLoadedPageIndex; i < tableData.length; i++) {
                tableRows[i].classList.add('blink-2');
            }
        }

        const listingTableWrapper = document.getElementById(tableElementId);

        if (listingTableWrapper) {
            const currentScrollPosition = listingTableWrapper.scrollTop;
            const newScrollPosition = currentScrollPosition + 250;
            const maxScroll = listingTableWrapper.scrollHeight - listingTableWrapper.clientHeight;
            const clampedScrollPosition = Math.min(maxScroll, Math.max(0, newScrollPosition));

            listingTableWrapper.scrollTo({
                top: clampedScrollPosition,
                behavior: 'smooth',
            });
        }
    };

    const tableColumnDefinition: columnsArrayType[] = [
        {
            id: '1',
            Header: 'Prompt Template Name',
            showHeader: true,
            accessor: 'promptTemplateName',
            isSortable: false,
            width: 320,
            minWidth: 320,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <p className="max-w-[320px] truncate">{deConstantCase(row?.original.templateKey)}</p>;
            },
        },
        {
            id: '2',
            Header: 'KPL',
            showHeader: true,
            accessor: 'kpl',
            isSortable: false,
            width: 190,
            minWidth: 190,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <Tag label={deUnderscore(row?.original.metadata.kpl || 'N/A')} appearance="sky" />;
            },
        },
        {
            id: '3',
            Header: 'Corresponding Model',
            showHeader: true,
            accessor: 'correspondingModel',
            isSortable: false,
            width: 190,
            minWidth: 190,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <Tag label={getPromptModelName(row?.original.metadata.model)} appearance="sky" />;
            },
        },
        {
            id: '4',
            Header: 'Category',
            showHeader: true,
            accessor: 'category',
            isSortable: false,
            width: 190,
            minWidth: 190,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                return <p>{deConstantCase(row?.original.category)}</p>;
            },
        },
        {
            id: '5',
            Header: 'Action',
            showHeader: true,
            accessor: 'action',
            isSortable: false,
            width: 200,
            minWidth: 200,
            isCustomizable: false,
            Cell: (props: any) => {
                const { row } = props || {};
                const isDeletable = Boolean(row?.original?.metadata?.isDeletable);

                return (
                    <div className="flex items-center justify-between gap-x-4">
                        <Button
                            view="ghost"
                            appearance="primary"
                            size="small"
                            onClick={() => {
                                setSelectedPrompt(row?.original);
                                setSelectedPromptAction(AdminPanelModalActions?.VIEW);
                                setPromptViewModalOpen(true);
                            }}
                        >
                            View Prompt
                        </Button>
                        <Dropdown
                            wrapperClassName="z-[9999] right-0"
                            selectionType="single"
                            triggerAction="onClick"
                            dropdownItems={
                                isDeletable
                                    ? [
                                          {
                                              id: 1,
                                              label: 'Edit',
                                              onClick: () => {
                                                  setSelectedPrompt(row?.original);
                                                  setSelectedPromptAction(AdminPanelModalActions?.EDIT);
                                                  setPromptViewModalOpen(true);
                                              },
                                          },
                                          {
                                              id: 2,
                                              label: 'Delete',
                                              className: 'text-R-500',
                                              onClick: () => {
                                                  setSelectedPrompt(row?.original);
                                                  setSelectedPromptAction(AdminPanelModalActions?.DELETE);
                                                  setPromptViewModalOpen(true);
                                              },
                                          },
                                      ]
                                    : [
                                          {
                                              id: 1,
                                              label: 'Edit',
                                              onClick: () => {
                                                  setSelectedPrompt(row?.original);
                                                  setSelectedPromptAction(AdminPanelModalActions?.EDIT);
                                                  setPromptViewModalOpen(true);
                                              },
                                          },
                                      ]
                            }
                        >
                            <Link appearance="neutral" iconBefore="ri-more-2-line" onClick={() => {}} />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    //below function need to be removed on integration and tableData will be set through query
    const fetchTableData = async () => {
        getPromptTemplates({
            variables: {
                criteria: {
                    category: category,
                    key: search === '' ? null : search?.replace(/[\s\n\t]/g, '_'),
                    kpl: kpl,
                    model: model,
                    page: 0,
                    pageSize: PAGE_SIZE,
                },
            },
            fetchPolicy: 'no-cache',
            onCompleted: ({ getPromptTemplateByCriteria }: any) => {
                setQueryPage(0);
                setDataRemaining(true);
                setTableData(getPromptTemplateByCriteria?.promptTemplates || []);
                setTotalPages(Number(getPromptTemplateByCriteria?.totalPages));
            },
        });
    };

    //Re fetch tableData query on filter changed
    useEffect(() => {
        //scroll to top when filter is applied
        scrollToTop();
        fetchTableData();
    }, [model, kpl, category, search, promptAction]);

    useEffect(() => {
        setCount && setCount(tableData.length || 0);
    }, [tableData]);

    const scrollToTop = () => {
        try {
            const listingTableWrapper = document.getElementById(tableElementId);
            if (listingTableWrapper) {
                listingTableWrapper?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            throw new Error('Prompts Table is not found');
        }
    };

    return {
        tableColumnDefinition,
        tableData,
        isPromptViewModalOpen,
        setPromptViewModalOpen,
        selectedPrompt,
        getPromptModelName,
        scrollableRef,
        tableElementId,
        loading,
        isLoadingMore,
        tableLoadingText,
        count,
        fetchTableData,
    };
};
