import { FilterCard, SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { capitalize } from 'lodash';
import { IAdminFilterContainerProps } from '../..';
import { ActiveStatus, ChatType } from '../../../../../__generated__/graphql';
import { ALL_VALUE, ALL_VALUE_CAPITALIZED, CHAT_TYPES, KPL_STATUSES } from '../../../../../constants';

export const KPLFilterContainer = ({ filterCriteria }: IAdminFilterContainerProps) => {
    const { chatType, setChatType, status, setStatus } = filterCriteria;

    return (
        <div className="admin-panel__filter-section sm:w-full  min-w-[296px] flex flex-col gap-y-6">
            <div className="min-h-[36px] flex items-center justify-between">
                <p className="text-[18px] font-600">Filter</p>
            </div>
            <div className="admin-panel__filter-card-container flex flex-col border border-N-200 border-b-0">
                <FilterCard
                    title="Chat type"
                    showClear={chatType !== ALL_VALUE_CAPITALIZED ? true : false}
                    show
                    handleClear={() => {
                        setChatType(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(chatType.toLowerCase()) || ''}
                            view="default"
                            items={[ALL_VALUE, ...CHAT_TYPES.map(type => capitalize(type.toLowerCase()))]}
                            onChange={(value: ChatType) => setChatType(value.toUpperCase() as ChatType)}
                        />
                    </div>
                </FilterCard>
                <FilterCard
                    title="Status"
                    showClear={status !== ALL_VALUE_CAPITALIZED ? true : false}
                    show
                    handleClear={() => {
                        setStatus(ALL_VALUE_CAPITALIZED);
                    }}
                >
                    <div className="flex flex-col gap-y-[12px]">
                        <SearchInput
                            className="w-full"
                            value={capitalize(status.toLowerCase()) || ''}
                            view="default"
                            onChange={(value: ActiveStatus) => setStatus(value.toUpperCase() as ActiveStatus)}
                            items={[ALL_VALUE, ...KPL_STATUSES.map(status => capitalize(status.toLowerCase()))]}
                        />
                    </div>
                </FilterCard>
            </div>
        </div>
    );
};
