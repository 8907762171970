import { SideBarButtonSelectionTypes, useChatContext, useSideBarContext } from '../../../../contexts';
import { useMobile } from '../../../../hooks';
import { CN } from '../../../../utils';
import { SideBarButton } from '../../buttons';

export const SidebarSecondaryBtnSection = () => {
    const { sideBarButtonSelection, setIsMenuCollapsed, isMenuCollapsed, setSideBarButtonSelection } =
        useSideBarContext();
    const { isCurrentUserAdmin, handleExportToPDF } = useChatContext();
    const isMobile = useMobile();

    const handleAdminButtonClick = () => {
        setSideBarButtonSelection(SideBarButtonSelectionTypes.AdminPanelSelection);
    };

    return (
        <div
            className={CN('sidebar_secondary_btn_section flex flex-col gap-y-3 px-4 sm:px-0 justify-center', {
                '!px-[24px]': isMobile,
            })}
        >
            {isCurrentUserAdmin && (
                <SideBarButton
                    label="Admin Panel"
                    type="action"
                    icon="ri-admin-fill"
                    onClick={handleAdminButtonClick}
                    buttonSelectionType={SideBarButtonSelectionTypes.AdminPanelSelection}
                    isIconOnly={!isMenuCollapsed}
                />
            )}
            {!(sideBarButtonSelection === SideBarButtonSelectionTypes.AdminPanelSelection) && (
                <>
                    <SideBarButton
                        label="Settings"
                        type="primary"
                        icon="ri-settings-3-fill"
                        buttonSelectionType={SideBarButtonSelectionTypes.SettingSelection}
                        isIconOnly={!isMenuCollapsed}
                    />
                    <SideBarButton
                        label="Export Chat"
                        type="action"
                        icon="ri-chat-download-line"
                        onClick={handleExportToPDF}
                        isIconOnly={!isMenuCollapsed}
                    />
                </>
            )}
            {!isMobile && (
                <SideBarButton
                    label={!isMenuCollapsed ? 'Maximize' : 'Minimize'}
                    type="action"
                    icon={!isMenuCollapsed ? 'ri-expand-right-line' : 'ri-expand-left-line'}
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                    isIconOnly={!isMenuCollapsed}
                />
            )}
        </div>
    );
};
